import React, { useEffect, useMemo, useState, useCallback } from 'react';
import {
    useReactTable,
    getCoreRowModel,
    getSortedRowModel,
    getFilteredRowModel,
    flexRender,
    createColumnHelper,
} from '@tanstack/react-table';
import { FaTrash } from 'react-icons/fa';
import { SiLangchain } from "react-icons/si";
import useApiRunStore from '../../store/api-runs-store';
import useApiClientStore from '../../store/api-clients-store.js';
import DeleteApiRunModal from './delete-modal.jsx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../components/Loader';
import { formatDate } from '../../utils/helper_functions';
import Pagination from '../../components/Pagination';

const pageSize = 50;
const columnHelper = createColumnHelper();

const ApiRuns = () => {
    const { apiRuns, totalApiRuns, totalPages, fetchApiRuns, deleteApiRun } = useApiRunStore();
    const { apiClients, fetchApiClients } = useApiClientStore();

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedApiRun, setSelectedApiRun] = useState(null);
    const [selectedClient, setSelectedClient] = useState('');

    const sortedClients = useMemo(() => {
        return apiClients.sort((a, b) => a.name.localeCompare(b.name));
    }, [apiClients]);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        await Promise.all([fetchApiRuns(currentPage, selectedClient), fetchApiClients()]);
        setIsLoading(false);
    }, [currentPage, selectedClient, fetchApiRuns, fetchApiClients]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        setCurrentPage(1);
    }, [selectedClient]);

    const data = useMemo(() => {
        return apiRuns.map((run) => {
            const client = apiClients.find((client) => client.id === run.client_id);
            if(client) {
                return {
                    ...run,
                    client_name: client.name,
                };
            }
            return run;
        });
    }, [apiRuns, apiClients]);

    const goToLangChainRunnableSequence = (run_id) => {
        const url = `https://smith.langchain.com/o/96ad2d11-9c39-5d7c-bb83-eeeb6f9481e0/projects/p/ddfe9499-e92e-468e-b444-980da965bf26/r/${run_id}`;
        window.open(url, '_blank');
    }

    const columns = useMemo(
        () => [
            columnHelper.accessor('id', {
                header: 'ID',
            }),
            columnHelper.accessor('client_name', {
                header: 'Client Name',
            }),
            columnHelper.accessor('total_prompt_tokens', {
                header: 'Total Prompt Tokens',
            }),
            columnHelper.accessor('total_completion_tokens', {
                header: 'Total Completion Tokens',
            }),
            columnHelper.accessor('created_at', {
                header: 'Created At',
                cell: (info) => formatDate(info.getValue()),
            }),
            columnHelper.accessor('actions', {
                header: 'Actions',
                cell: ({ row }) => (
                    <div className="flex space-x-4 items-center">
                        <FaTrash
                            title="Delete"
                            onClick={() => handleOpenDeleteModal(row.original)}
                            className="cursor-pointer text-red-600"
                        />

                        <SiLangchain
                            title="Langchain"
                            className="cursor-pointer"
                            size={28}
                            onClick={() => goToLangChainRunnableSequence(row.original.run_id)}
                        />
                    </div>
                ),
            }),
        ],
        []
    );

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
    });

    const startRange = (currentPage - 1) * pageSize + 1;
    const endRange = Math.min(currentPage * pageSize, totalApiRuns);
    const noRunsFound = table.getRowModel().rows.length === 0;

    const handleOpenDeleteModal = (apiRun) => {
        setSelectedApiRun(apiRun);
        setIsDeleteModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsDeleteModalOpen(false);
        setSelectedApiRun(null);
    };

    const handleDeleteApiRun = async (id) => {
        try {
            await deleteApiRun(id);
            toast.success('API Run deleted successfully');
            handleCloseModal();
        } catch (error) {
            console.error('Failed to delete API run:', error);
            toast.error('Failed to delete API run. Please try again.');
        }
    };

    return (
        <div className="container mx-auto mt-4">
            <h1 className="text-2xl font-semibold mb-4 py-1">API Runs List</h1>
            <div className="flex mb-4">
                <select
                    name="client_name"
                    value={selectedClient}
                    onChange={(e) => { setSelectedClient(e.target.value) }}
                    className={`px-10 border py-2 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 ${!selectedClient && 'text-gray-500'}`}
                >
                    <option value="" className='text-gray-500'>Select Client</option>
                    {sortedClients.map((client) => (
                        <option key={client.id} value={client.id}>
                            {client.name}
                        </option>
                    ))}
                </select>
            </div>
            <div className="overflow-x-auto shadow-md rounded-lg">
                <table className="min-w-full bg-white border border-gray-300">
                    <thead className="bg-gray-50">
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <th
                                        key={header.id}
                                        className="py-3 px-4 border-b border-gray-200 text-center text-sm font-semibold text-gray-700"
                                    >
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                        <span>
                                            {header.column.getIsSorted()
                                                ? header.column.getIsSortedDesc()
                                                    ? ' 🔽'
                                                    : ' 🔼'
                                                : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <tr>
                                <td colSpan={columns.length}>
                                    <Loader className="flex justify-center items-center h-[60vh]" />
                                </td>
                            </tr>
                        ) : noRunsFound ? (
                            <tr>
                                <td colSpan={columns.length}>
                                    <p className="flex justify-center items-center h-[40vh]">No API runs found</p>
                                </td>
                            </tr>
                        ) : (
                            table.getRowModel().rows.map((row) => (
                                <tr key={row.id} className="hover:bg-gray-50">
                                    {row.getVisibleCells().map((cell) => (
                                        <td key={cell.id} className="py-3 px-4 border-b border-gray-200 text-sm text-gray-700">
                                            <div className="flex justify-center">
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>

            <div className="flex flex-col-reverse gap-2 sm:flex-row my-4 justify-between lg:justify-center lg:gap-4 items-center md:items-center">
                <div className="text-[#868C98] max-w-[50%] text-sm sm:items-center flex flex-col overflow-hidden">
                    {isLoading || noRunsFound ? null :
                        <p className="mr-2 mb-2 whitespace-nowrap overflow-hidden">
                            Showing <span className="font-bold">{startRange}</span> - <span className="font-bold">{endRange}</span> of <span className="font-bold">{totalApiRuns}</span> runs
                        </p>
                    }
                    <Pagination
                        total={totalApiRuns}
                        totalPages={totalPages}
                        pageSize={pageSize}
                        page={currentPage}
                        setPage={setCurrentPage}
                        className="mt-2 sm:mt-0 flex justify-center"
                    />
                </div>
            </div>

            <DeleteApiRunModal
                isOpen={isDeleteModalOpen}
                onRequestClose={handleCloseModal}
                run={selectedApiRun}
                onDelete={handleDeleteApiRun}
            />
        </div>
    );
};

export default ApiRuns;