import React, { useEffect, useMemo, useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
  createColumnHelper,
} from '@tanstack/react-table';
import { FaPlus, FaKey, FaPen, FaTrash, FaCheck, FaTimes } from 'react-icons/fa';
import useUserStore from '../../store/user-store';
import CreateUserModal from './create-user-modal';
import EditUserModal from './edit-user-modal';
import DeleteUserModal from './delete-user-modal';
import ResetPasswordModal from '../../components/reset-password'; // Import the new modal
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../components/Loader';
import { formatDate } from '../../utils/helper_functions';

const columnHelper = createColumnHelper();

const UsersTable = () => {
  const { users, fetchUsers, createUser, editUser, deleteUser, setUserPassword } = useUserStore();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [globalFilter, setGlobalFilter] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await fetchUsers();
      setIsLoading(false);
    }
    fetchData();
  }, [fetchUsers]);

  const data = useMemo(() => {
    return users.sort((a, b) => a.name.localeCompare(b.name));
  }, [users]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: 'ID',
      }),
      columnHelper.accessor('user_id', {
        header: 'User ID',
      }),
      columnHelper.accessor('name', {
        header: 'Name',
      }),
      columnHelper.accessor('service_name', {
        header: 'Service Name',
      }),
      columnHelper.accessor('paying_customer', {
        header: 'Paying Customer',
        cell: info => info.getValue() === true ? <FaCheck className="text-green-500" /> : <FaTimes className="text-red-600" />,
      }),
      columnHelper.accessor('words', {
        header: 'Words',
      }),
      columnHelper.accessor('max_word_limit_for_report', {
        header: 'Max Words Limit',
      }),
      columnHelper.accessor('created_at', {
        header: 'Created At',
        cell: (info) => formatDate(info.getValue()),
      }),
      columnHelper.accessor('trial_end_date', {
        header: 'Trial End Date',
        cell: (info) => formatDate(info.getValue()),
      }),
      columnHelper.accessor('docx_template_s3_bucket_name', {
        header: 'S3 Bucket Name',
        cell: info => info.getValue() ? info.getValue() : '-',
      }),
      columnHelper.accessor('docx_template_file_name', {
        header: 'Template File Name',
        cell: info => info.getValue() ? info.getValue() : '-',
      }),
      columnHelper.accessor('actions', {
        header: 'Actions',
        cell: ({ row }) => (
          <div className="flex space-x-4 items-center">
            <FaPen
              title="Edit"
              onClick={() => handleOpenEditModal(row.original)}
              className="cursor-pointer text-blue-500"
            />
            <FaTrash
              title="Delete"
              onClick={() => handleOpenDeleteModal(row.original)}
              className="cursor-pointer text-red-600"
            />
            <FaKey
              title="Reset Password"
              onClick={() => handleOpenResetPasswordModal(row.original)}
              className="cursor-pointer text-yellow-600"
            />
          </div>
        ),
      }),
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    state: { globalFilter },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  useEffect(() => {
    console.log('Selected user changed:', selectedUser); // Debug log
  }, [selectedUser]);
  
  // Handlers for modals
  const handleOpenCreateModal = () => setIsCreateModalOpen(true);
  const handleOpenEditModal = (user) => {
    console.log('Opening edit modal for user:', user); // Debug log
    setSelectedUser(user);
    setIsEditModalOpen(true);
  };
  const handleOpenDeleteModal = (user) => {
    setSelectedUser(user);
    setIsDeleteModalOpen(true);
  };
  const handleOpenResetPasswordModal = (user) => {
    setSelectedUser(user);
    setIsResetPasswordModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsCreateModalOpen(false);
    setIsEditModalOpen(false);
    setIsDeleteModalOpen(false);
    setIsResetPasswordModalOpen(false);
    setSelectedUser(null);
  };

  const handleCreateUser = async (userData) => {
    await createUser(userData);
  };

  const handleEditUser = async (id, userData) => {
    await editUser(id, userData);
  };

  const handleDeleteUser = async (id) => {
    try {
      await deleteUser(id);
      toast.success('User deleted successfully');
      handleCloseModal();
    } catch (error) {
      console.error('Failed to delete user:', error);
      toast.error('Failed to delete user. Please try again.');
    }
  };

  const handleResetPassword = async (userId, newPassword) => {
    try {
      await setUserPassword(userId, newPassword);
      toast.success('Password reset successfully');
      handleCloseModal();
    } catch (error) {
      console.error('Failed to reset password:', error);
      toast.error('Failed to reset password. Please try again.');
    }
  };

  const noUsersFound = table.getRowModel().rows.length === 0;

  return (
    <div className="container mx-auto mt-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-semibold">User List</h1>
        <button
          className="flex items-center px-4 py-2 bg-black text-white rounded-md shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          onClick={handleOpenCreateModal}
        >
          <FaPlus className="mr-2" /> Create New
        </button>
      </div>
      <div className="mb-4">
        <input
          type="text"
          value={globalFilter}
          autoComplete="off"
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
        />
      </div>
      <div className={`overflow-x-auto relative border border-gray-300 rounded-[3px] ${isLoading && 'h-[65vh]'} ${noUsersFound && 'h-[50vh]'}`}>
        <table className="min-w-full bg-white">
          <thead className="bg-gray-50">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={`py-3 px-4 border-b border-gray-200 text-center text-sm font-semibold text-gray-700
                      ${header.column.id === 'actions' ? 'sticky -right-[1px] bg-gray-50 sm:min-w-36' : ''}
                      ${header.column.id.length < 10 && header.column.id !== 'actions' ? 'min-w-36 sm:min-w-44' : ''}
                      ${header.column.id.length >= 10 && header.column.id.length < 15 ? 'min-w-52 sm:min-w-52' : ''}
                      ${header.column.id.length >= 15 ? 'min-w-64 sm:min-w-64' : ''}
                    `}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    <span>
                      {header.column.getIsSorted()
                        ? header.column.getIsSortedDesc()
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {
              isLoading ? (
                <tr><td colSpan={columns.length}><Loader className="absolute top-[58%] left-1/2 transform -translate-x-1/2 -translate-y-1/2" /></td></tr>
              ) :
                noUsersFound ? (
                  <tr><td colSpan={columns.length}><p className="absolute top-[58%] left-1/2 transform -translate-x-1/2 -translate-y-1/2">No users found</p></td></tr>
                ) :
                  table.getRowModel().rows.map((row) => (
                    <tr key={row.id} className="hover:bg-gray-50">
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} 
                          className={`py-3 px-4 border-b border-gray-200 text-sm text-gray-700 ${cell.column.id === 'actions' ? 'sticky -right-[1px] bg-gray-50' : ''}`}
                        >
                          <div className="flex justify-center">{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                        </td>
                      ))}
                    </tr>
              ))}
          </tbody>
        </table>
      </div>
      <CreateUserModal
        isOpen={isCreateModalOpen}
        onRequestClose={handleCloseModal}
        onCreate={handleCreateUser}
      />
      <EditUserModal
        isOpen={isEditModalOpen}
        onRequestClose={handleCloseModal}
        user={selectedUser}
        onEdit={handleEditUser}
      />
      <DeleteUserModal
        isOpen={isDeleteModalOpen}
        onRequestClose={handleCloseModal}
        user={selectedUser}
        onDelete={handleDeleteUser}
      />
      <ResetPasswordModal
        isOpen={isResetPasswordModalOpen}
        onRequestClose={handleCloseModal}
        userId={selectedUser?.user_id} // Pass userId to the ResetPasswordModal
        setUserPassword={handleResetPassword}
      />
      {/* <ToastContainer /> */}
    </div>
  );
};

export default UsersTable;
