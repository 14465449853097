import React from 'react';
import { FaTimes } from 'react-icons/fa';

const DeleteApiClientModal = ({ isOpen, onRequestClose, onDelete, client }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="fixed inset-0 bg-black bg-opacity-30" onClick={onRequestClose}></div>
            <div className="bg-white rounded-lg shadow-lg max-w-md w-full p-6 relative z-10">
                <FaTimes className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 cursor-pointer" onClick={onRequestClose} size={12} />
                <h2 className="text-xl font-semibold mb-4">Delete client</h2>
                <p>Are you sure you want to remove client <strong>{client?.name}</strong>?</p>
                <div className="flex justify-end mt-4">
                    <button
                        type="button"
                        onClick={onRequestClose}
                        className="px-4 py-2 border rounded-md shadow-sm focus:outline-none bg-gray-500 text-white mr-2"
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        onClick={() => onDelete(client.id)}
                        className="px-4 py-2 border rounded-md shadow-sm focus:outline-none bg-red-500 text-white"
                    >
                        Delete
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeleteApiClientModal;
