import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
  createColumnHelper,
} from '@tanstack/react-table';
import { FaEye, FaPen, FaTrash, FaCheck, FaTimes, FaSyncAlt } from 'react-icons/fa';
import { SiLangchain } from "react-icons/si";
import useReportStore from '../../store/report-store';
import useUserStore from '../../store/user-store'
import Loader from '../../components/Loader';
import Pagination from '../../components/Pagination';
import DeleteReportModal from './delete-report-modal';
import ViewReportModal from './view-report-modal';
import EditReportModal from './edit-report-modal';
import useDebounce from '../../utils/useDebounce';
import { formatDate } from '../../utils/helper_functions';
import { handleUpdateStatus } from '../../utils/helper_functions';

const pageSize = 50;

const columnHelper = createColumnHelper();

const Reports = () => {
  const { reports, totalReports, totalPages, fetchReports, fetchReport, setReport, deleteReport, editReport, retryLoading, setRetryLoading} = useReportStore();
  const { users, fetchUsers } = useUserStore();

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [selectedUser, setSelectedUser] = useState(''); 
  const [expandedRows, setExpandedRows] = useState({});
  const [globalFilter, setGlobalFilter] = useState('');

  const reportsData = useMemo(() => {
    return reports.map((report) => {
      const user = users.find((user) => user.id === report.user_id);
      if (user) {
        return { ...report, user_name: user.name };
      }
      return report;
    });
  }, [reports, users]);

  const debouncedGlobalFilter = useDebounce(globalFilter, 500);
  const search = useMemo(() => `${debouncedGlobalFilter} ${selectedUser}`, [debouncedGlobalFilter, selectedUser]);
  const searchParam = search.trim();
  console.log(`search:${searchParam}`);


  const sortedUsers = useMemo(() => {
    return users.sort((a, b) => a.name.localeCompare(b.name));
  }, [users]);

  const loadReports = useCallback(async () => {
    setLoading(true);
    await fetchUsers();
    await fetchReports(currentPage, searchParam);
    setLoading(false);
  }, [fetchReports, currentPage, searchParam, fetchUsers]);

  useEffect(() => {
    loadReports();
  }, [loadReports]);

  useEffect(() => {
    setCurrentPage(1);
  }, [debouncedGlobalFilter]);

  const handleViewClick = useCallback((report) => {
    setReport(report);
    setSelectedReport(report);
    setIsViewModalOpen(true);
  }, [setReport]);

  const handleEditClick = useCallback((report) => {
    setReport(report);
    setSelectedReport(report);
    setIsEditModalOpen(true);
  }, [setReport]);

  const handleDeleteClick = (report) => {
    setSelectedReport(report);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteReport = async (id) => {
    try {
      await deleteReport(id);
      toast.success('Report deleted successfully');
      setIsDeleteModalOpen(false);
    }
    catch (error) {
      console.error('Error deleting report', error);
      toast.error('Report could not be deleted');
    }
  }

  const handleEditReport = async (id, updatedData) => {
    try {
      await editReport(id, updatedData);
      toast.success('Report edited successfully');
      setIsEditModalOpen(false);
    }
    catch (error) {
      console.error('Error editing report', error);
      toast.error('Report could not be edited');
    }
  }

  const handleToggleExpand = useCallback((rowId, columnId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: {
        ...prev[rowId],
        [columnId]: !prev[rowId]?.[columnId]
      }
    }));
  }, [setExpandedRows]);

  const handleRetry = useCallback(async (id) => {
    setRetryLoading(id, true);
    await handleUpdateStatus({ documentId: id, status: 'retry' });
    await fetchReport(id);
  }, [setRetryLoading, fetchReport]);

  const renderTranscriptionCell = useCallback((info, columnId) => {
    const isExpanded = expandedRows[info.row.id]?.[columnId];
    const value = info.getValue() || '-';
    return (
      <div>
        {isExpanded ? value : value.length > 50 ? `${value.slice(0, 50)}...` : value}
        {value.length > 50 && (
          <button
            onClick={() => handleToggleExpand(info.row.id, columnId)}
            className="ml-2 text-blue-500"
          >
            {isExpanded ? 'Show Less' : 'Show More'}
          </button>
        )}
      </div>
    );
  }, [expandedRows, handleToggleExpand]);

  const goToLangChainRunnableSequence = (run_id) => {
    const url = `https://smith.langchain.com/o/96ad2d11-9c39-5d7c-bb83-eeeb6f9481e0/projects/p/ddfe9499-e92e-468e-b444-980da965bf26/r/${run_id}`;
    window.open(url, '_blank');
  }

  const columns = useMemo(
    () => [
      columnHelper.accessor('row_number', {
        header: 'Report #',
      }),
      columnHelper.accessor('id', {
        header: 'ID',
      }),
      columnHelper.accessor('user_name', {
        header: 'User Name',
      }),
      columnHelper.accessor('created_at', {
        header: 'Created At',
        cell: (info) => formatDate(info.getValue()),
      }),
      columnHelper.accessor('report_language', {
        header: 'Language',
      }),
      columnHelper.accessor('patient_name', {
        header: 'Patient Name',
      }),
      columnHelper.accessor('doctor_name', {
        header: 'Doctor Name',
      }),
      columnHelper.accessor('clinic_name', {
        header: 'Clinic Name',
      }),
      columnHelper.accessor('error', {
        header: 'Error Description',
        cell: info => info.getValue() ? info.getValue() : '-',
      }),
      columnHelper.accessor('audio_language', {
        header: 'Audio Language',
      }),
      columnHelper.accessor('status', {
        header: 'Status',
      }),
      columnHelper.accessor('user_id', {
        header: 'User ID',
      }),
      columnHelper.accessor('comment', {
        header: 'Comment',
        cell: (info) => renderTranscriptionCell(info, 'nova_transcription'),
      }),
      columnHelper.accessor('confidence', {
        header: 'Confidence',
      }),
      columnHelper.accessor('report_words', {
        header: 'Report Words',
      }),
      columnHelper.accessor('words_deducted_from_user', {
        header: 'Actual Words deducted',
      }),
      columnHelper.accessor('template', {
        header: 'Template',
      }),
      columnHelper.accessor('report_fetched', {
        header: 'Report Fetched',
        cell: info => info.getValue() === true ? <FaCheck className="text-green-500" /> : <FaTimes className="text-red-600" />,
      }),
      columnHelper.accessor('retried_at', {
        header: 'Retried At',
        cell: (info) => formatDate(info.getValue()),
      }),
      columnHelper.accessor('meta_type', {
        header: 'Meta Type',
      }),
      columnHelper.accessor('uses_custom_template', {
        header: 'Uses Custom Template',
        cell: info => info.getValue() === true ? <FaCheck className="text-green-500" /> : <FaTimes className="text-red-600" />,
      }),
      columnHelper.accessor('nova_transcription', {
        header: 'Nova Transcription',
        cell: (info) => renderTranscriptionCell(info, 'nova_transcription'),
      }),
      columnHelper.accessor('whisper_transcription', {
        header: 'Whisper Transcription',
        cell: (info) => renderTranscriptionCell(info, 'whisper_transcription'),
      }),
      columnHelper.accessor('rev_ai_transcription', {
        header: 'Rev AI Transcription',
        cell: (info) => renderTranscriptionCell(info, 'rev_ai_transcription'),
      }),
      columnHelper.accessor('assembly_ai_transcription', {
        header: 'Assembly AI Transcription',
        cell: (info) => renderTranscriptionCell(info, 'assembly_ai_transcription'),
      }),
      columnHelper.accessor('actions', {
        header: 'Actions',
        cell: ({ row }) => (
          <div className="flex justify-around items-center space-x-4">
            <FaEye
              title="View"
              onClick={() => handleViewClick(row.original)}
              className="cursor-pointer text-green-500"
            />
            <FaPen
              title="Edit"
              onClick={() => handleEditClick(row.original)}
              className="cursor-pointer text-blue-500"
            />
            <FaTrash
              title="Delete"
              onClick={() => handleDeleteClick(row.original)}
              className="cursor-pointer text-red-600"
            />
            {row.original.langchain_run_id && <SiLangchain
                title="Langchain"
                className="cursor-pointer"
                size={28}
                onClick={() => goToLangChainRunnableSequence(row.original.langchain_run_id)}
              />
            }
            {((row.original.status === 'completed' && !row.original.report_fetched) || retryLoading[row.original.id] || row.original.status === 'processing') && (
              retryLoading[row.original.id] || row.original.status === 'processing' ? (
                <div title='Processing'>
                  <Loader className="text-yellow-500" />
                </div>
              ) : (
                <FaSyncAlt
                  title="Retry"
                  onClick={() => handleRetry(row.original.id)}
                  className="cursor-pointer text-yellow-500"
                />
              )
            )}
          </div>
        ),
      }),
    ],
    [handleViewClick, handleEditClick, renderTranscriptionCell, handleRetry, retryLoading]
  );
  
  const table = useReactTable({
    data: reportsData,
    columns,
    state: { searchParam },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const startRange = (currentPage - 1) * pageSize + 1;
  const endRange = Math.min(currentPage * pageSize, totalReports);
  const noReportsFound = table.getRowModel().rows.length === 0;


  return (
    <div className="container mx-auto mt-4">
      <h1 className="text-2xl font-semibold mb-4 py-1">Reports List</h1>
      <div className="flex gap-4 mb-4">
        <input
          type="text"
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          autoComplete="off"
          placeholder="Search reports..."
          className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
        />
        <select
          name="user_id"
          value={selectedUser}
          onChange={(e) => { setSelectedUser(e.target.value) }}
          className={`px-4 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 ${!selectedUser && 'text-gray-500'}`}
        >
          <option value="" className='text-gray-500'>Select User</option>
          {sortedUsers.map((user) => (
            <option key={user.id} value={user.id}>
              {user.name}
            </option>
          ))}
        </select>
      </div>
      <div className={`overflow-x-auto relative border border-gray-300 rounded-[3px] ${loading && 'h-[65vh]'} ${noReportsFound && 'h-[50vh]'}`}>
        <table className="min-w-full bg-white">
          <thead className="bg-gray-50">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={`py-3 px-4 border-b border-gray-200 text-center text-sm font-semibold text-gray-700
                      ${header.column.id === 'actions' ? 'sticky -right-[1px] bg-gray-50 sm:min-w-36' : ''}
                      ${header.column.id.length < 10 && header.column.id !== 'actions' ? 'min-w-36 sm:min-w-44' : ''}
                      ${header.column.id.length >= 10 && header.column.id.length < 15 ? 'min-w-52 sm:min-w-52' : ''}
                      ${header.column.id.length >= 15 ? 'min-w-64 sm:min-w-64' : ''}
                    `}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    <span>
                      {header.column.getIsSorted()
                        ? header.column.getIsSortedDesc()
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={columns.length}>
                  <Loader className="absolute top-[58%] left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
                </td>
              </tr>
            ) : noReportsFound ? (
              <tr>
                <td colSpan={columns.length}>
                    <h1 className="absolute top-[58%] left-1/2 transform -translate-x-1/2 -translate-y-1/2">No reports found</h1>
                </td>
              </tr>
            ) : (
              table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className={`py-3 px-4 border-b border-gray-200 text-sm text-gray-700 ${cell.column.id === 'actions' ? 'sticky -right-[1px] bg-gray-50' : ''}
                          ${(row.original.status === 'completed' && !row.original.report_fetched) && 'bg-red-50'}
                          ${row.original.status === 'processing' && 'bg-orange-50'}
                      `}
                    >
                      <div className="flex justify-center">{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="flex flex-col-reverse gap-2 sm:flex-row my-4 justify-between lg:justify-center lg:gap-4 items-center md:items-center">
        <div className="text-[#868C98] max-w-[50%] text-sm sm:items-center flex flex-col overflow-hidden">
          {loading || noReportsFound ? null :
            <p className="mr-2 mb-2 whitespace-nowrap overflow-hidden">
              Showing <span className="font-bold">{startRange}</span> - <span className="font-bold">{endRange}</span> of <span className="font-bold">{totalReports}</span> reports
            </p>
          }
          <Pagination 
            total={totalReports} 
            totalPages={totalPages} 
            pageSize={pageSize} 
            page={currentPage} 
            setPage={setCurrentPage} 
            className="mt-2 sm:mt-0 flex justify-center" 
          />
        </div>
      </div>

      <ViewReportModal
        isOpen={isViewModalOpen}
        onRequestClose={() => setIsViewModalOpen(false)}
        report={selectedReport}
      />

      <EditReportModal
        isOpen={isEditModalOpen}
        onRequestClose={() => setIsEditModalOpen(false)}
        report={selectedReport}
        onEdit={handleEditReport}
      />

      <DeleteReportModal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        report={selectedReport}
        onDelete={handleDeleteReport}
      />
    </div>
  );
};

export default Reports;