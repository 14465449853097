import { create } from 'zustand';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';

const useReportStore = create((set) => ({
    report: null,
    reports: [],
    totalReports: 0,
    totalPages: 0,
    retryLoading: {},
    setRetryLoading: (id, loadingState) => set((state) => ({
        retryLoading: { ...state.retryLoading, [id]: loadingState }
    })),
    setReport: (report) => set({ report }),
    updateReport: (report) => set((state) => ({
        reports: state.reports.map((r) => (r.id === report.id ? report : r)),
    })),
    clearReport: () => set({ report: null }),
    fetchReports: async (page = 1, search = '') => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.get(`${process.env.REACT_APP_ADMIN_API}/admin/reports`,{
                headers: {
                  'Authorization': `Bearer ${accessToken.toString()}`,
                  'Content-Type': 'application/json'
                },
                params: { page, search }
              });
            set({
                reports: response.data.reports,
                totalReports: response.data.totalReports,
                totalPages: response.data.totalPages,
            });
        } catch (error) {
            console.error('Failed to fetch reports:', error);
        }
    },
    fetchReport: async (id) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.get(`${process.env.REACT_APP_ADMIN_API}/admin/reports/${id}`,{
                headers: {
                  'Authorization': `Bearer ${accessToken.toString()}`,
                  'Content-Type': 'application/json'
                },
              });
            set((state) => ({
                report: response.data.report,
                reports: state.reports.map((report) =>
                    report.id === id ? response.data.report : report
                ),
            }));
            console.log("Report: ", response.data.report);
        } catch (error) {
            console.error('Failed to fetch report:', error);
        }
    },
    deleteReport: async (id) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            await axios.delete(`${process.env.REACT_APP_ADMIN_API}/admin/reports/${id}`, {
                headers: {
                  'Authorization': `Bearer ${accessToken.toString()}`,
                  'Content-Type': 'application/json'
                },
              });
            set((state) => ({
                reports: state.reports.filter((report) => report.id !== id),
            }));
        } catch (error) {
            console.error('Failed to delete report:', error);
        }
    },
    editReport: async (id, updatedData) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.patch(`${process.env.REACT_APP_ADMIN_API}/admin/reports/${id}`, updatedData, {
                headers: {
                  'Authorization': `Bearer ${accessToken.toString()}`,
                  'Content-Type': 'application/json'
                },
              });
            set((state) => ({
                reports: state.reports.map((report) =>
                    report.id === id ? response.data.report : report
                ),
                report: response.data.report,
            }));
            console.log("Report updated: ", response.data.report);
        } catch (error) {
            console.error('Failed to edit report:', error);
        }
    },
}));

export default useReportStore;
