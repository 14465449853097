import React from 'react';
import { signOut } from 'aws-amplify/auth';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import useUserStore from '../store/user-store'; // Adjust the import path as necessary

const Navbar = () => {
  const navigate = useNavigate();
  const clearUser = useUserStore((state) => state.clearUser); // Use the clearUser function from the store

  const handleLogout = async () => {
    await signOut();
    localStorage.removeItem('user_id');
    clearUser(); // Clear the user state from the store
    navigate('/');
  };

  return (
    <nav className="bg-gray-800">
      <div className="container mx-auto px-6">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="text-white font-semibold text-xl">
              MPAssist
            </Link>
          </div>
          <div className="flex items-center space-x-4">
            <Link to="/reports" className="text-gray-300 hover:text-white">
              Reports
            </Link>
            <Link to="/users" className="text-gray-300 hover:text-white">
              Users
            </Link>
            <Link to="/constants" className="text-gray-300 hover:text-white">
              Constants
            </Link>
            <Link to="/templates" className="text-gray-300 hover:text-white">
              Templates
            </Link>
            <Link to="/api-clients" className="text-gray-300 hover:text-white">
              API Clients
            </Link>
            <Link to="/api-runs" className="text-gray-300 hover:text-white">
              API Runs
            </Link>
            <button onClick={handleLogout} className="text-gray-300 hover:text-white">
              Logout
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
