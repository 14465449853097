import React, { useEffect, useMemo, useState } from 'react';
import {
    useReactTable,
    getCoreRowModel,
    getSortedRowModel,
    getFilteredRowModel,
    flexRender,
    createColumnHelper,
} from '@tanstack/react-table';
import { FaPlus, FaPen, FaTrash } from 'react-icons/fa';
import useApiClientStore from '../../store/api-clients-store.js';
import CreateApiClientModal from './create-modal.jsx';
import UpdateApiClientModal from './update-modal.jsx';
import DeleteApiClientModal from './delete-modal.jsx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../components/Loader';

const columnHelper = createColumnHelper();

const ApiClients = () => {
    const { apiClients, fetchApiClients, createApiClient, updateApiClient, deleteApiClient } = useApiClientStore();

    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedApiClient, setSelectedApiClient] = useState(null);
    const [globalFilter, setGlobalFilter] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await fetchApiClients();
            setIsLoading(false);
        };

        fetchData();
    }, [fetchApiClients]);

    const data = useMemo(() => {
        return apiClients.sort((a, b) => a.name.localeCompare(b.name));
    }, [apiClients]);

    const columns = useMemo(
        () => [
            columnHelper.accessor('id', {
                header: 'ID',
            }),
            columnHelper.accessor('name', {
                header: 'Name',
            }),
            columnHelper.accessor('total_runs', {
                header: 'Total Runs',
            }),
            columnHelper.accessor('total_prompt_tokens', {
                header: 'Total Prompt Tokens',
            }),
            columnHelper.accessor('total_completion_tokens', {
                header: 'Total Completion Tokens',
            }),
            columnHelper.accessor('api_key', {
                header: 'API Key',
            }),
            columnHelper.accessor('actions', {
                header: 'Actions',
                cell: ({ row }) => (
                    <div className="flex space-x-4 items-center">
                        <FaPen
                            title="Edit"
                            onClick={() => handleOpenEditModal(row.original)}
                            className="cursor-pointer text-blue-500"
                        />
                        <FaTrash
                            title="Delete"
                            onClick={() => handleOpenDeleteModal(row.original)}
                            className="cursor-pointer text-red-600"
                        />
                    </div>
                ),
            }),
        ],
        []
    );

    const table = useReactTable({
        data,
        columns,
        state: { globalFilter },
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
    });

    const handleOpenCreateModal = () => setIsCreateModalOpen(true);

    const handleOpenEditModal = (apiClient) => {
        setSelectedApiClient(apiClient);
        setIsUpdateModalOpen(true);
    };

    const handleOpenDeleteModal = (apiClient) => {
        setSelectedApiClient(apiClient);
        setIsDeleteModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsCreateModalOpen(false);
        setIsUpdateModalOpen(false);
        setIsDeleteModalOpen(false);
        setSelectedApiClient(null);
    };

    const handleCreateApiClient = async (name, api_key) => {
        try {
            await createApiClient(name, api_key);
            toast.success('API Client created successfully');
            handleCloseModal();
        } catch (error) {
            console.error('Failed to create API client:', error);
            toast.error('Failed to create API client. Please try again.');
        }
    };

    const handleUpdateApiClient = async (id, updatedData) => {
        try {
            await updateApiClient(id, updatedData);
            handleCloseModal();
        } catch (error) {
            console.error('Failed to update API client:', error);
        }
    };

    const handleDeleteApiClient = async (id) => {
        try {
            await deleteApiClient(id);
            toast.success('API Client deleted successfully');
            handleCloseModal();
        } catch (error) {
            console.error('Failed to delete API client:', error);
            toast.error('Failed to delete API client. Please try again.');
        }
    };

    return (
        <div className="container mx-auto mt-4">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-semibold">API Clients List</h1>
                <button
                    className="flex items-center px-4 py-2 bg-black text-white rounded-md shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    onClick={handleOpenCreateModal}
                >
                    <FaPlus className="mr-2" /> Create New
                </button>
            </div>
            <div className="mb-4">
                <input
                    type="text"
                    value={globalFilter}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    placeholder="Search..."
                    className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
            </div>
            <div className="overflow-x-auto shadow-md rounded-lg">
                <table className="min-w-full bg-white border border-gray-300">
                    <thead className="bg-gray-50">
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <th
                                        key={header.id}
                                        className="py-3 px-4 border-b border-gray-200 text-center text-sm font-semibold text-gray-700"
                                    >
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                        <span>
                                            {header.column.getIsSorted()
                                                ? header.column.getIsSortedDesc()
                                                    ? ' 🔽'
                                                    : ' 🔼'
                                                : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {
                            isLoading ? (
                                <tr><td colSpan={columns.length}><Loader className='flex justify-center items-center h-[60vh]' /></td></tr>
                            ) :
                                table.getRowModel().rows.length === 0 ? (
                                    <tr><td colSpan={columns.length}><p className='flex justify-center items-center h-[40vh]'>No API clients found</p></td></tr>
                                ) :
                                    table.getRowModel().rows.map((row) => (
                                        <tr key={row.id} className="hover:bg-gray-50">
                                            {row.getVisibleCells().map((cell) => (
                                                <td key={cell.id} className="py-3 px-4 border-b border-gray-200 text-sm text-gray-700">
                                                    <div className="flex justify-center">{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                    </tbody>
                </table>
            </div>
            <CreateApiClientModal
                isOpen={isCreateModalOpen}
                onRequestClose={handleCloseModal}
                onCreate={handleCreateApiClient}
            />
            <UpdateApiClientModal
                isOpen={isUpdateModalOpen}
                onRequestClose={handleCloseModal}
                client={selectedApiClient}
                onUpdate={handleUpdateApiClient}
            />
            <DeleteApiClientModal
                isOpen={isDeleteModalOpen}
                onRequestClose={handleCloseModal}
                client={selectedApiClient}
                onDelete={handleDeleteApiClient}
            />
        </div>
    );
};

export default ApiClients;
