import { create } from 'zustand';
import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';

const useUserStore = create((set) => ({
  user: null,
  users: [],
  setUser: (user) => set({ user }),
  clearUser: () => set({ user: null }),
  
  fetchUsers: async () => {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const response = await axios.get(`${process.env.REACT_APP_ADMIN_API}/admin/user/get-all`, {
        headers: {
          'Authorization': `Bearer ${accessToken.toString()}`,
          'Content-Type': 'application/json'
        },
      });
      set({ users: response.data.users });
      console.log("users ----", response.data.users)
    } catch (error) {
      console.error('Failed to fetch users:', error);
    }
  },

  fetchUserById: async (id) => {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const response = await axios.get(`${process.env.ADMIN_API}/admin/user/${id}`, {
        headers: {
          'Authorization': `Bearer ${accessToken.toString()}`,
        },
      });
      return response.data.user;
    } catch (error) {
      console.error('Failed to fetch user by ID:', error);
      throw error;
    }
  },

  createUser: async (userData) => {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/admin/user/create`, userData, {
        headers: {
          'Authorization': `Bearer ${accessToken.toString()}`,
        },
      });
      set((state) => ({ users: [...state.users, response.data.user] }));
      return response.data.user;
    } catch (error) {
      console.error('Failed to create user:', error);
      throw error;
    }
  },

  // editUser: async (id, userData) => {
  //   try {
  //     const { accessToken } = (await fetchAuthSession()).tokens ?? {};
  //     const response = await axios.patch(`${process.env.REACT_APP_ADMIN_API}/admin/user/edit/${id}`, userData, {
  //       headers: {
  //         'Authorization': `Bearer ${accessToken.toString()}`,
  //       },
  //     });
  //     set((state) => ({
  //       users: state.users.map((user) => (user.id === id ? response.data.user : user)),
  //     }));
  //     return response.data.user;
  //   } catch (error) {
  //     console.error('Failed to edit user:', error);
  //     throw error;
  //   }
  // },

  editUser: async (id, userData) => {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const response = await axios.patch(`${process.env.REACT_APP_ADMIN_API}/admin/user/edit/${id}`, userData, {
        headers: {
          'Authorization': `Bearer ${accessToken.toString()}`,
        },
      });
    
      // Logging API response
      console.log('API response:', response.data);
    
      set((state) => ({
        users: state.users.map((user) => (user.id === id ? response.data.user : user)),
      }));
    
      console.log("Updated user", response.data.user);
      return response.data.user; // This should return the updated user
    } catch (error) {
      console.error('Failed to edit user:', error);
    
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response) {
        if (error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        } else {
          errorMessage = error.response.statusText || errorMessage;
        }
      } else if (error.message) {
        errorMessage = error.message;
      }
    
      throw new Error(errorMessage);
    }
    
  },
  
  

  

  deleteUser: async (id) => {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const response = await axios.delete(`${process.env.REACT_APP_ADMIN_API}/admin/user/delete/${id}`, {
        headers: {
          'Authorization': `Bearer ${accessToken.toString()}`,
        },
      });
      set((state) => ({
        users: state.users.filter((user) => user.id !== id),
      }));
      return response.data.message;
    } catch (error) {
      console.error('Failed to delete user:', error);
      throw error;
    }
  },

  // setUserPassword: async (email, newPassword) => {
  //   try {
  //     const session = await fetchAuthSession();
  //     if (!session?.tokens?.accessToken) {
  //       throw new Error('Authentication session could not be established');
  //     }
  //     const { accessToken } = session.tokens;
  
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_ADMIN_API}/admin/user/admin-set-password`,
  //       { email, newPassword },
  //       {
  //         headers: {
  //           'Authorization': `Bearer ${accessToken}`,
  //         },
  //       }
  //     );
  
  //     return response.data.message;
  //   } catch (error) {
  //     if (error.response?.data?.message) {
  //       throw new Error(error.response.data.message);
  //     } else if (error.message) {
  //       throw new Error(error.message);
  //     } else {
  //       throw new Error('Failed to set password');
  //     }
  //   }
  // },

  setUserPassword: async (userId, newPassword) => {
    try {
      const session = await fetchAuthSession();
      if (!session?.tokens?.accessToken) {
        throw new Error('Authentication session could not be established');
      }
      const { accessToken } = session.tokens;

      const response = await axios.post(
        `${process.env.REACT_APP_ADMIN_API}/admin/user/admin-set-password`,
        { userId, newPassword },
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        }
      );

      return response.data.message;
    } catch (error) {
      if (error.response?.data?.message) {
        throw new Error(error.response.data.message);
      } else if (error.message) {
        throw new Error(error.message);
      } else {
        throw new Error('Failed to set password');
      }
    }
  },

 
  

  sendOTP: async (email) => {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/admin/user/forgot-password`, { email }, {
        headers: {
          'Authorization': `Bearer ${accessToken.toString()}`,
        },
      });
      return response.data.message;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  resetPassword: async (email, confirmationCode, newPassword) => {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/admin/user/confirm-password`, {
        email,
        confirmationCode,
        newPassword,
      }, {
        headers: {
          'Authorization': `Bearer ${accessToken.toString()}`,
        },
      });
      return response.data.message;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
}));

export default useUserStore;
