import React, { useEffect, useMemo, useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
  createColumnHelper,
} from '@tanstack/react-table';
import { FaPen, FaTrash, FaPlus } from 'react-icons/fa';
import useConstantStore from '../../store/constants-store';
import UpdateModal from './update-modal'; 
import CreateConstantModal from './create-constant-modal';
import DeleteConstantModal from './delete-constant-modal';
import Loader from '../../components/Loader';
import { formatDate } from '../../utils/helper_functions';
import { toast } from 'react-toastify';

const columnHelper = createColumnHelper();

const Constants = () => {
  const { constants, fetchConstants, updateConstant, deleteConstant, createConstant } = useConstantStore();

  const [globalFilter, setGlobalFilter] = useState('');
  const [expandedRows, setExpandedRows] = useState({});
  const [isEditModalOpen, setisEditModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedConstant, setSelectedConstant] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await fetchConstants();
      setIsLoading(false);
    }
    fetchData();
  }, [fetchConstants]);

  const data = useMemo(() => {
    return constants.sort((a, b) => a.name.localeCompare(b.name));
  }, [constants]);

  const handleToggleExpand = (id) => {
    setExpandedRows((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleUpdate = (id, name, value) => {
    try {
      updateConstant(id, name, value);
      toast.success('Constant updated successfully');
    } catch (error) {
      toast.error('Failed to update constant. Please try again.');
      console.error('Failed to update constant:', error);
    }
  };

  const handleCreateConstant = async (name, value) => {
    try {
      await createConstant(name, value);
      toast.success('Constant created successfully');
    } catch (error) {
      toast.error('Failed to create constant. Please try again.');
      console.error('Failed to create constant:', error);
    }
  };

  const handleDeleteConstant = async (name, value) => {
    try {
      await deleteConstant(name, value);
      toast.success('Constant deleted successfully');
      setIsDeleteModalOpen(false);
    } catch (error) {
      toast.error('Failed to delete constant. Please try again.');
      console.error('Failed to delete constant:', error);
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: 'ID',
      }),
      columnHelper.accessor('name', {
        header: 'Name',
      }),
      columnHelper.accessor('value', {
        header: 'Value',
        cell: (info) => {
          const isExpanded = expandedRows[info.row.id];
          const value = info.getValue();
          return (
            <div>
              {isExpanded ? value : value.length > 50 ? `${value.slice(0, 50)}...` : value}
              {value.length > 50 && (
                <button
                  onClick={() => handleToggleExpand(info.row.id)}
                  className="ml-2 text-blue-500"
                >
                  {isExpanded ? 'Show Less' : 'Show More'}
                </button>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor('created_at', {
        header: 'Created At',
        cell: (info) => formatDate(info.getValue()),
      }),
      columnHelper.accessor('actions', {
        header: 'Actions',
        cell: ({ row }) => (
          <div className="flex space-x-4">
            <FaPen
              title="Update"
              onClick={() => {
                setSelectedConstant(row.original);
                setisEditModalOpen(true);
              }}
              className="cursor-pointer text-blue-500"
            />
            <FaTrash
              title="Delete"
              onClick={() => {
                setSelectedConstant(row.original);
                setIsDeleteModalOpen(true);
              }}
              className="cursor-pointer text-red-600"
            />
          </div>
        ),
      }),
    ],
    [expandedRows]
  );

  const table = useReactTable({
    data,
    columns,
    state: { globalFilter },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <div className="container mx-auto mt-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-semibold">Constants List</h1>
        <button
          className="flex items-center px-4 py-2 bg-black text-white rounded-md shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          onClick={() => setIsCreateModalOpen(true)}
        >
          <FaPlus className="mr-2" /> Create New
        </button>
      </div>
      <div className="mb-4">
        <input
          type="text"
          value={globalFilter}
          autoComplete="off"
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
        />
      </div>
      <div className="overflow-x-auto shadow-md rounded-lg">
        <table className="min-w-full bg-white border border-gray-300">
          <thead className="bg-gray-50">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="py-3 px-4 border-b border-gray-200 text-center text-sm font-semibold text-gray-700"
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    <span>
                      {header.column.getIsSorted()
                        ? header.column.getIsSortedDesc()
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {
              isLoading ? (
                <tr><td colSpan={columns.length}><Loader className='flex justify-center items-center h-[60vh]' /></td></tr>
              ) :
                table.getRowModel().rows.length === 0 ? (
                  <tr><td colSpan={columns.length}><p className='flex justify-center items-center h-[40vh]'>No constants found</p></td></tr>
                ) :
                  table.getRowModel().rows.map((row) => (
                    <tr key={row.id} className="hover:bg-gray-50">
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} className="py-3 px-4 border-b border-gray-200 text-sm text-gray-700">
                          <div className="flex justify-center">{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                        </td>
                      ))}
                    </tr>
                  ))}
          </tbody>
        </table>
      </div>
      <CreateConstantModal
        isOpen={isCreateModalOpen}
        onRequestClose={() => setIsCreateModalOpen(false)}
        onCreate={handleCreateConstant}
      />
        <UpdateModal
          isOpen={isEditModalOpen}
          onRequestClose={() => setisEditModalOpen(false)}
          constant={selectedConstant}
          onUpdate={handleUpdate}
        />
        <DeleteConstantModal
          isOpen={isDeleteModalOpen}
          onRequestClose={() => setIsDeleteModalOpen(false)}
          onDelete={handleDeleteConstant}
          constant={selectedConstant}
        />
    </div>
  );
};

export default Constants;
