import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';

const EditUserModal = ({ isOpen, onRequestClose, user, onEdit }) => {
  const [formData, setFormData] = useState({
    name: '',
    service_name: '',
    words: '',
    max_word_limit_for_report: '',
    docx_template_s3_bucket_name: '',
    docx_template_file_name: '',
    paying_customer: false,
    created_at: '',
    trial_end_date: '',
  });

  const [initialData, setInitialData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  useEffect(() => {
    if (user) {
      const initialFormData = {
        name: user.name || '',
        service_name: user.service_name || '',
        words: user.words || '',
        max_word_limit_for_report: user.max_word_limit_for_report || '',
        docx_template_s3_bucket_name: user.docx_template_s3_bucket_name || '',
        docx_template_file_name: user.docx_template_file_name || '',
        paying_customer: user.paying_customer || false,
        created_at: user.created_at ? new Date(user.created_at).toISOString().split('.')[0] : '',
        trial_end_date: user.trial_end_date ? new Date(user.trial_end_date).toISOString().split('.')[0] : '',
      };
      setFormData(initialFormData);
      setInitialData(initialFormData);
    }
  }, [user]);

  const trimData = (data) => {
    return Object.keys(data).reduce((acc, key) => {
      acc[key] = typeof data[key] === 'string' ? data[key].trim() : data[key];
      return acc;
    }, {});
  };

  useEffect(() => {
    if (initialData) {
      const trimmedFormData = trimData(formData);
      const trimmedInitialData = trimData(initialData);

      const isFormDataChanged = JSON.stringify(trimmedFormData) !== JSON.stringify(trimmedInitialData);
      setIsSaveDisabled(!isFormDataChanged);
    }
  }, [formData, initialData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const trimmedFormData = trimData(formData);
      await onEdit(user.id, trimmedFormData);
      toast.success('User updated successfully');
      onRequestClose();
    } catch (error) {
      console.error('Failed to edit user:', error);
      toast.error(error.message || 'Failed to edit user. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black bg-opacity-30" onClick={onRequestClose}></div>
      <div className="bg-white rounded-lg shadow-lg max-w-xl w-full p-6 relative z-10 max-h-screen overflow-y-auto">
        <FaTimes className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 cursor-pointer" onClick={onRequestClose} size={12} />
        <h2 className="text-xl font-semibold mb-4">Edit User</h2>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div className="flex flex-col mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                required
              />
            </div>
            <div className="flex flex-col mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Service Name</label>
              <input
                type="text"
                name="service_name"
                value={formData.service_name}
                onChange={handleChange}
                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                required
              />
            </div>
            <div className="flex flex-col mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Words</label>
              <input
                type="text"
                name="words"
                value={formData.words}
                onChange={handleChange}
                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                required
              />
            </div>
            <div className="flex flex-col mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Max Word Limit</label>
              <input
                type="text"
                name="max_word_limit_for_report"
                value={formData.max_word_limit_for_report}
                onChange={handleChange}
                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                required
              />
            </div>
            <div className="flex flex-col mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">S3 Bucket Name</label>
              <input
                type="text"
                name="docx_template_s3_bucket_name"
                value={formData.docx_template_s3_bucket_name}
                onChange={handleChange}
                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
            </div>
            <div className="flex flex-col mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">File Name</label>
              <input
                type="text"
                name="docx_template_file_name"
                value={formData.docx_template_file_name}
                onChange={handleChange}
                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
            </div>
            <div className="flex flex-col mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Created At</label>
              <input
                type="datetime-local"
                name="created_at"
                value={formData.created_at}
                onChange={handleChange}
                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
            </div>
            <div className="flex flex-col mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Trial End Date</label>
              <input
                type="datetime-local"
                name="trial_end_date"
                value={formData.trial_end_date}
                onChange={handleChange}
                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
            </div>
            <div className="flex items-center mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2 mr-2">Paying Customer</label>
              <input
                type="checkbox"
                name="paying_customer"
                checked={formData.paying_customer}
                onChange={handleChange}
                className="form-checkbox h-5 w-5 text-indigo-600"
              />
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <button
              type="button"
              onClick={onRequestClose}
              className="px-4 py-2 border rounded-md shadow-sm focus:outline-none bg-gray-500 text-white mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`px-4 py-2 border rounded-md shadow-sm focus:outline-none ${isSaveDisabled ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-blue-500 text-white'}`}
              disabled={isSaveDisabled}
            >
              {isLoading ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditUserModal;
